export const toggleHamburgerMenu = ({ button, content }) => {
	const headerMenuButton = document.querySelector(button);
	const headerMenuContent = document.querySelector(content);

	headerMenuButton.addEventListener("click", () => {
		const contentOpenState =
			headerMenuContent.dataset.open === "true" ? "false" : "true";

		headerMenuButton.setAttribute("aria-expanded", contentOpenState);
		headerMenuContent.dataset.open = contentOpenState;
	});
};
