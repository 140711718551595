// Scroll to show shadow on header
const header = document.querySelector("header");
const SCROLL_DISTANCE = 20;
const THROTTLE_TIME = 100;

function throttle(func, limit) {
	let lastFunc;
	let lastRan;
	return function () {
		const context = this;
		const args = arguments;
		if (!lastRan) {
			func.apply(context, args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(
				function () {
					if (Date.now() - lastRan >= limit) {
						func.apply(context, args);
						lastRan = Date.now();
					}
				},
				limit - (Date.now() - lastRan),
			);
		}
	};
}

window.addEventListener(
	"scroll",
	throttle(() => {
		const shouldShadow = window.scrollY >= SCROLL_DISTANCE ? "true" : "false";

		if (header.dataset.shadow !== shouldShadow) {
			header.dataset.shadow = shouldShadow;
		}
	}, THROTTLE_TIME),
);
